import React from "react";
import styled from "styled-components";

const ResultDiv = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);

  gap: 1rem;
`;
const ResultCard = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.7rem;
  box-shadow: ${({ selected, theme }) =>
    selected
      ? "0px 4px 20px 2px rgba(194, 10, 235, 0.3)"
      : "0px 4px 20px 2px rgba(0, 0, 0, 0.1)"};
`;
const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.7rem;
`;
const PercentageDiv = styled.div`
  padding: 1.5rem 1.5rem;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  //background-color: ${({ theme }) => theme.color.__main_black};
  background-color: white;
  border-radius: 0.7rem;
  color: black;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
`;
const Div = styled.div`
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

function CardResult({ itemData, choiceIndex }) {
  return (
    <ResultDiv>
      {itemData.item.map((item, i) => {
        return (
          <ResultCard key={i} selected={i === choiceIndex} rate={item.vote}>
            <SlideImage src={"/img/items/" + item.img} />
            <Div>
              <PercentageDiv selected={i === choiceIndex}>
                {item.vote}
              </PercentageDiv>
            </Div>
          </ResultCard>
        );
      })}
    </ResultDiv>
  );
}

export default CardResult;
