import React from "react";
import styled from "styled-components";
import Navigation from "../components/Navigation";
import MainHeader from "../components/MainHeader";
import data from "../data/data.json";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  height: 5%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 0.8rem;
  font-weight: 500;
`;

const ListDiv = styled.div`
  width: 100%;
  height: 75%;
  overflow: scroll;
  padding: 0 2rem;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ProfileDiv = styled.div`
  display: grid;
  width: 100%;
  height: 14rem;

  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
  grid-template-rows: 1fr;

  justify-items: center;
  align-items: center;
  margin-bottom: 2rem;

  gap: 1rem;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.7rem;
`;

const ProfileDetailBlock = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  background-color: white;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.7rem;

  font-size: 0.7rem;
`;

const ProfileName = styled.div`
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
`;

const ProfileLocation = styled.div``;

const ProfileCareer = styled.div``;

const ProfileTarget = styled.div``;

const ProfileInfoButton = styled.section`
  width: 48%;
  background-color: white;
  color: black;
  display: flex;
  font-weight: 600;

  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:active {
    background-color: black;
    color: white;
  }
`;
const ProfileButtonBlock = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

const SubTitleP = styled.p`
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

function StylistPage() {
  const navigate = useNavigate();

  const onChatClick = () => {
    ReactGA.event({
      category: "button",
      action: `click_stylist_chat`,
    });

    navigate("/introduction");
  };

  const onReservationClick = () => {
    ReactGA.event({
      category: "button",
      action: `click_stylist_reservation`,
    });

    navigate("/introduction");
  };

  return (
    <Container>
      <MainHeader />
      <ListDiv>
        <SubTitle>
          <SubTitleP>
            스타일리스트를 선택하여 이들과 상담부터 동행쇼핑까지 함께하는 <br />
            면대면 퍼스널 스타일링을 받을 수 있습니다.
          </SubTitleP>
        </SubTitle>
        {data.stylist.map((stylist, i) => (
          <ProfileDiv key={i}>
            <ProfileImg src={"/img/profiles/" + stylist.img} />
            <ProfileDetailBlock>
              <ProfileName>{stylist.name}</ProfileName>
              <ProfileLocation>{stylist.location}</ProfileLocation>
              <ProfileCareer>{stylist.career}</ProfileCareer>
              <ProfileTarget>{stylist.target}</ProfileTarget>
              <ProfileButtonBlock>
                <ProfileInfoButton
                  onClick={() => {
                    onChatClick();
                  }}
                >
                  온라인 상담
                </ProfileInfoButton>
                <ProfileInfoButton
                  onClick={() => {
                    onReservationClick();
                  }}
                >
                  대면 쇼핑 예약
                </ProfileInfoButton>
              </ProfileButtonBlock>
            </ProfileDetailBlock>
          </ProfileDiv>
        ))}
      </ListDiv>
      <Navigation tone={"white"} />
    </Container>
  );
}

export default StylistPage;
