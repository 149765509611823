import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 10%;
  width: 100%;
  padding: 0rem 2rem 0rem 2rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: linear-gradient(0deg, #ffffff, #f7eafa);
`;

const LogoDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  // background-color: white;
`;

const LogoImg = styled.img`
  width: 7rem;
  // height: 100%;
  // margin-left: auto;
  // margin-right: auto;
  object-fit: contain;
`;

const InfoDiv = styled.div`
  display: flex;
  height: 100%;
  width: 8rem;
  align-items: center;
  justify-content: end;
`;

const InfoButton = styled.section`
  text-align: center;
  text-decoration: underline;
  font-size: 0.8rem;
  cursor: pointer;
  text-shadow: 0px 2px 10px rgba(220, 94, 248, 0.5);
  &:hover {
    text-shadow: 0px 4px 10px rgba(220, 94, 248, 0.5);
  }
`;

function MainHeader() {
  const navigate = useNavigate();

  return (
    <Container>
      <LogoDiv>
        <LogoImg src={"/img/logo.png"} />
      </LogoDiv>
      <InfoDiv>
        <InfoButton
          onClick={() => {
            navigate("/introduction");
            ReactGA.event({
              category: "button",
              action: `click_about`,
            });
          }}
        >
          더 알아보기
        </InfoButton>
      </InfoDiv>
    </Container>
  );
}

export default MainHeader;
