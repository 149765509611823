import React from "react";
import styled from "styled-components";
import { TbHanger, TbVersions, TbPlus } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Container = styled.section`
  height: 15%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
`;

const ListButton = styled.div`
  background-color: ${(props) =>
    props.tone === "white" ? "white" : "#F1F1F1"};
  margin-right: 2rem;
  height: 100%;
  width: 35%;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  flex-direction: column;

  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
`;
const NewButton = styled.div`
  background-color: ${(props) =>
    props.tone === "white" ? "white" : "#F1F1F1"};
  margin-right: 2rem;
  height: 100%;
  width: 35%;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
  border-radius: 0.7rem;
`;
const ButtonP = styled.p`
  font-size: 0.8rem;
  text-align: center;
`;
const ButtonIconP = styled.p`
  font-size: 1.3rem;
  text-align: center;
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
const IndicatorP = styled.p`
  font-size: 0.6rem;
  margin: 0;
  @media only screen and (max-height: 700px) {
    font-size: 0rem;
  }
`;
const StylistButton = styled.div`
  background-color: ${(props) =>
    props.tone === "white" ? "white" : "#F1F1F1"};
  height: 100%;
  width: 35%;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  flex-direction: column;

  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
`;

function Navigation({ tone }) {
  const navigate = useNavigate();

  return (
    <Container>
      <ListButton
        tone={tone}
        onClick={() => {
          navigate("/");
        }}
      >
        <ButtonIconP>
          <TbVersions />
        </ButtonIconP>
        <IndicatorP>투표 목록</IndicatorP>
      </ListButton>
      <NewButton
        tone={tone}
        onClick={() => {
          navigate("/create");
        }}
      >
        <ButtonIconP>
          <TbPlus />
        </ButtonIconP>
        <IndicatorP>투표 만들기</IndicatorP>
      </NewButton>
      <StylistButton
        tone={tone}
        onClick={() => {
          navigate("/stylist");
        }}
      >
        <ButtonIconP>
          <TbHanger />
        </ButtonIconP>
        <IndicatorP>스타일리스트</IndicatorP>
      </StylistButton>
    </Container>
  );
}

export default Navigation;
