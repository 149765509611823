import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardIndex: 0,
  choiceIndex: -1,
  showSwiper: true,
  showResult: false,
  showLoading: false,
  sentEmail: false,
};

export const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    incrementCardIndex: (state) => {
      state.cardIndex++;
    },
    setChoiceIndex: (state, action) => {
      state.choiceIndex = action.payload;
    },
    resetChoiceIndex: (state) => {
      state.choiceIndex = -1;
    },
    resetCardIndex: (state) => {
      state.cardIndex = 0;
    },
    setShowSwiper: (state, action) => {
      state.showSwiper = action.payload;
    },
    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },
    setShowLoading: (state, action) => {
      state.showLoading = action.payload;
    },
    setSentEmail: (state, action) => {
      state.sentEmail = action.payload;
    },
  },
});

export const {
  incrementCardIndex,
  setChoiceIndex,
  resetChoiceIndex,
  resetCardIndex,
  setShowLoading,
  setShowSwiper,
  setShowResult,
  setSentEmail,
} = actionSlice.actions;

export default actionSlice.reducer;
