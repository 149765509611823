import { createGlobalStyle } from "styled-components";
import CooperBlack from "../font/cooper_black.otf";

const GlobalStyle = createGlobalStyle`
  html {
    // font-size: 62.5%;
    // font-family: "Spoqa Han Sans";
    // font-display: swap;
    // letter-spacing: -0.03rem;
  }
  body {

    margin: 0;
    padding: 0;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.color.__main_background_grey};

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  @font-face{
    font-family: 'Cooper Black';
    src: url(${CooperBlack}) format('otf')
  }

  .swiper-pagination-bullet-active {
    background-color: #000 !important;
  }
  .swiper-container {
    overflow: visible !important;
  }
  .swiper-pagination{
    position: absolute !important;
    bottom: -2rem !important;
  }
  .swiper-pagination-bullet {
    border-radius: 0 !important;
  }

  /////////////////////
  CSStransition-related
  /////////////////////

  .float-appear {
    opacity: 0;
    transform: scale(0.95);
  }
  .float-appear-active {
    opacity: 1;
    transform: scale(1.0);
    transition: opacity 300ms, transform 300ms;
  }

  .float-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  .float-enter-active {
    opacity: 1;
    transform: scale(1.00);
    transition: opacity 300ms, transform 300ms;
  }
  
  .float-exit {
    opacity: 1;
  }
  .float-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 300ms, transform 300ms;
  }

`;

export default GlobalStyle;
