import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const useTracking = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_API_KEY }]);
    setIsActive(true);
  }, []);

  useEffect(() => {
    if (isActive) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location]);
};

export default useTracking;
