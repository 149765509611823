const color = {
  __main_violet_900: "#A509C8",
  __main_violet_500: "#C20AEB",
  __main_violet_200: "#ED9EFF",
  __main_peach_500: "#FF607C",
  __main_peach_200: "#FFAAB9",
  __main_pink_300: "#F2A2D6",
  __light_violet_900: "#D789D9",
  __light_violet_700: "#CE99F2",
  __light_violet_500: "#E8C4F2",
  __light_violet_300: "#ECDCF2",
  __main_black: "#000000",
  __main_background_grey: "#E6E6E6",
  __main_background_light_grey: "#F2F2F2",
};

export default { color };
