import React from "react";
import styled from "styled-components";
import Navigation from "../components/Navigation";
import MainHeader from "../components/MainHeader";
import data from "../data/data.json";
import { PieChart } from "react-minimal-pie-chart";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
`;

const DefaultModal = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PieChartModal = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TitleDiv = styled.div`
  height: 10%;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  font-size: 1rem;
  font-weight: 500;
`;

const PieChartDiv = styled.div`
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.8rem;
  font-weight: 500;
`;
const PieChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  font-size: 0.2rem;
  margin-bottom: 2rem;
`;

function DetailPage() {
  const { state } = useLocation();
  const { isPageOne } = state;
  return (
    <Container>
      <MainHeader />
      {isPageOne ? (
        <PieChartModal>
          <TitleDiv>투표 상세 결과</TitleDiv>
          <PieChartDiv>
            <PieChartContainer>
              <TitleDiv>1번 옷</TitleDiv>
              <PieChart
                data={[
                  { title: "10대 여성", value: 6, color: "#e8c4f2" },
                  { title: "20대 여성", value: 29, color: "#e7c0f1" },
                  { title: "30대 여성", value: 15, color: "#deabed" },
                  { title: "40대 여성", value: 3, color: "#d696e8" },
                  { title: "10대 남성", value: 13, color: "#9c29bc" },
                  { title: "20대 남성", value: 10, color: "#8b25a7" },
                  { title: "30대 남성", value: 2, color: "#7a2092" },
                  { title: "40대 남성", value: 5, color: "#681c7d" },
                ]}
                label={({ dataEntry }) =>
                  dataEntry.title + " (" + dataEntry.value + ")"
                }
                radius={45}
                labelPosition={70}
                labelStyle={{
                  fontSize: "0.3rem",
                  fontWeight: "500",
                  textShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                }}
              />
            </PieChartContainer>
            <PieChartContainer>
              <TitleDiv>2번 옷</TitleDiv>
              <PieChart
                data={[
                  { title: "10대 여성", value: 9, color: "#e8c4f2" },
                  { title: "20대 여성", value: 8, color: "#e7c0f1" },
                  { title: "30대 여성", value: 7, color: "#deabed" },
                  { title: "40대 남성", value: 4, color: "#681c7d" },
                ]}
                label={({ dataEntry }) =>
                  dataEntry.title + " (" + dataEntry.value + ")"
                }
                radius={45}
                labelPosition={70}
                labelStyle={{
                  fontSize: "0.3rem",
                  fontWeight: "500",
                  textShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                }}
              />
            </PieChartContainer>
            <PieChartContainer>
              <TitleDiv>3번 옷</TitleDiv>
              <PieChart
                data={[
                  { title: "10대 여성", value: 15, color: "#e8c4f2" },
                  { title: "20대 여성", value: 30, color: "#e7c0f1" },
                  { title: "30대 여성", value: 14, color: "#deabed" },
                  { title: "10대 남성", value: 35, color: "#9c29bc" },
                  { title: "20대 남성", value: 21, color: "#8b25a7" },
                  { title: "30대 남성", value: 12, color: "#7a2092" },
                ]}
                label={({ dataEntry }) =>
                  dataEntry.title + " (" + dataEntry.value + ")"
                }
                radius={45}
                labelPosition={70}
                labelStyle={{
                  fontSize: "0.3rem",
                  fontWeight: "500",
                  textShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                }}
                roun
              />
            </PieChartContainer>
            <PieChartContainer>
              <TitleDiv>4번 옷</TitleDiv>
              <PieChart
                data={[
                  { title: "10대 여성", value: 12, color: "#e8c4f2" },
                  { title: "20대 여성", value: 15, color: "#e7c0f1" },
                  { title: "30대 여성", value: 9, color: "#deabed" },
                  { title: "10대 남성", value: 3, color: "#9c29bc" },
                  { title: "20대 남성", value: 9, color: "#8b25a7" },
                  { title: "40대 남성", value: 1, color: "#681c7d" },
                ]}
                label={({ dataEntry }) =>
                  dataEntry.title + " (" + dataEntry.value + ")"
                }
                radius={45}
                labelPosition={70}
                labelStyle={{
                  fontSize: "0.3rem",
                  fontWeight: "500",
                  textShadow: "0px 0px 0px rgba(0, 0, 0, 0.5)",
                }}
              />
            </PieChartContainer>
          </PieChartDiv>
        </PieChartModal>
      ) : (
        <DefaultModal>아직 정보가 충분하지 않습니다</DefaultModal>
      )}
      <Navigation tone={"white"} />
    </Container>
  );
}

export default DetailPage;
