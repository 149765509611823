import React from "react";
import styled from "styled-components";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination } from "swiper";
// import "swiper/css";
// import "swiper/css/pagination";

// const ImageDiv = styled.div`
//   width: 100%;
//   height: calc(var(--vh, 1vh) * 49);;
//   max-height: 610px;
//   background-color: white;
//   box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
//   // box-sizing: border-box;
//   // border: ${(props) => (props.selected === true ? "solid 1px" : "none")}};
//   // border-color: ${({ theme }) => theme.color.__main_violet_500};
//   ${(props) =>
//     props.selected === true
//       ? `  &:before{
//     font-family: Cooper Black;
//     content: "";
//     width: 100%;
//     height: 100%;
//     background-color: rgba(237, 158, 255, 0.4);
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }`
//       : "none"}};
// `;

// const SlideImage = styled.img`
//   width: 100%;
//   height: calc(var(--vh, 1vh) * 49);
//   max-height: 610px;
//   object-fit: cover;
// `;

const ResultDiv = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);

  gap: 1rem;
`;
const ResultCard = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.7rem;
  ${(props) =>
    props.selected === true
      ? `  &:before{
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(237, 158, 255, 0.2);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  `
      : ""};
`;
const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.7rem;
`;

function CardSwiper({ onCardClick, itemData, choiceIndex }) {
  return (
    // <Swiper
    //   modules={[Pagination]}
    //   pagination={{ clickable: true }}
    //   spaceBetween={20}
    //   slidesPerView={1}
    //   // onSlideChange={() => console.log("slide change")}
    //   // onSwiper={(swiper) => console.log(swiper)}
    //   style={{ overflow: "visible" }}
    // >
    //   {itemData.item.map((item, i) => (
    //     <SwiperSlide key={i}>
    //       <ImageDiv
    //         selected={i === choiceIndex}
    //         onClick={() => {
    //           onCardClick(i);
    //         }}
    //       >
    //         <SlideImage key={i} src={"/img/items/" + item.img} />
    //       </ImageDiv>
    //     </SwiperSlide>
    //   ))}
    // </Swiper>
    <ResultDiv>
      {itemData.item.map((item, i) => {
        return (
          <ResultCard
            key={i}
            onClick={() => {
              onCardClick(i);
            }}
            selected={i === choiceIndex}
          >
            <SlideImage key={i} src={"/img/items/" + item.img} />
          </ResultCard>
        );
      })}
    </ResultDiv>
  );
}

export default CardSwiper;
