import React from "react";
import styled from "styled-components";
import { CgArrowLongRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TbHanger } from "react-icons/tb";
import ReactGA from "react-ga4";

const Container = styled.section`
  height: 15%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
`;

const ShowDetailButton = styled.div`
  background-color: white;
  margin-right: 2rem;
  height: 100%;
  width: 25%;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
`;
const ProceedButton = styled.div`
  background-color: white;
  height: 100%;
  width: 50%;
  margin-right: 2rem;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-radius: 0.7rem;
  &:active {
    background-color: white;
    color: black;
  }
`;
const ButtonP = styled.p`
  font-size: 0.8rem;
  text-align: center;
`;
const ButtonIconP = styled.p`
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.3rem;
`;
const IndicatorP = styled.p`
  font-size: 0.6rem;
  margin: 0;
  @media only screen and (max-height: 700px) {
    font-size: 0rem;
  }
`;
const StylistIconP = styled.p`
  font-size: 1.3rem;
  text-align: center;
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
const StylistButton = styled.div`
  background-color: "white";
  height: 100%;
  width: 25%;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  flex-direction: column;

  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
`;

function ResultController({ nextHandler }) {
  const navigate = useNavigate();
  const { cardIndex } = useSelector((state) => state.action);

  return (
    <Container>
      <ShowDetailButton
        onClick={() => {
          navigate("/detail", { state: { isPageOne: cardIndex === 0 } });
          ReactGA.event({
            category: "button",
            action: `click_detail_on_result`,
          });
        }}
      >
        <ButtonP>자세히</ButtonP>
      </ShowDetailButton>
      <ProceedButton
        onClick={() => {
          nextHandler();
        }}
      >
        <ButtonP>다음 투표</ButtonP>
        <ButtonIconP>
          <CgArrowLongRight />
        </ButtonIconP>
      </ProceedButton>
      <StylistButton
        onClick={() => {
          navigate("/stylist");
        }}
      >
        <StylistIconP>
          <TbHanger />
        </StylistIconP>
        <IndicatorP>스타일리스트</IndicatorP>
      </StylistButton>
    </Container>
  );
}

export default ResultController;
