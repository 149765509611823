import React, { useState } from "react";
import styled from "styled-components";
import MainHeader from "../components/MainHeader";
import Navigation from "../components/Navigation";
import CardSwiper from "../components/CardSwiper";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { BarLoader } from "react-spinners";
import {
  incrementCardIndex,
  setChoiceIndex,
  resetChoiceIndex,
  resetCardIndex,
  setShowLoading,
  setShowResult,
  setShowSwiper,
} from "../slice/actionSlice";

import data from "../data/data.json";
import CardResult from "../components/CardResult";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ResultController from "../components/ResultController";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
`;

const QuestionSection = styled.section`
  width: 100%;
  height: 20%;
  box-sizing: border-box;
  padding: 0rem 2rem 0rem 2rem;
  overflow: visible;
`;
const QuestionDiv = styled.div`
  height: 70%;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 0.7rem;
`;
const QuestionP = styled.p`
  text-align: center;
  font-size: 0.8rem;
`;

const IdItem = styled.div`
  font-size: 0.8rem;
  text-align: center;
  width: 20%;

  padding: 0.4rem 0;
  margin-right: 0.4rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  background-color: white;
  color: black;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 0.7rem;
`;

const InfoDiv = styled.div`
  height: 30%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const TagDiv = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  white-space: nowrap;
  overflow: visible;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const TagItem = styled.div`
  padding: 0.4rem 0.4rem;
  font-size: 0.8rem;
  // margin-right: 0.4rem;
  // background-color: lightgrey;
  color: black;
  // box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 0.7rem;
  background-color: ${({ theme }) => theme.color.__main_violet_100};
`;

const ImageSection = styled.section`
  width: 100%;
  height: 55%;
  box-sizing: border-box;
  padding: 2rem 2rem 0rem 2rem;
  overflow: visible;
`;

const LoadingDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function VotePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cardIndex, choiceIndex, showSwiper, showLoading, showResult } =
    useSelector((state) => state.action);

  const itemData = data.users[cardIndex];
  const cardClickHandler = (i) => {
    ReactGA.event({
      category: "button",
      action: `click_${i}th_card`,
    });

    dispatch(setChoiceIndex(i));
    dispatch(setShowSwiper(false));
    setTimeout(() => {
      dispatch(setShowLoading(true));
    }, 300);
    setTimeout(() => {
      dispatch(setShowLoading(false));
    }, 900);
    setTimeout(() => {
      dispatch(setShowResult(true));
    }, 1200);
  };

  const nextCardHandler = () => {
    dispatch(setShowResult(false));
    setTimeout(() => {
      dispatch(resetChoiceIndex());
      dispatch(incrementCardIndex());
      if (cardIndex > 6) {
        navigate("/introduction");
        dispatch(resetCardIndex());
      } else {
        dispatch(setShowSwiper(true));
      }
    }, 300);
  };

  return (
    <Container>
      <MainHeader></MainHeader>
      <QuestionSection>
        <InfoDiv>
          <IdItem>{itemData.name} 님</IdItem>
          <TagDiv>
            {itemData.tag.map((tag, i) => (
              <TagItem key={i}>#{tag}</TagItem>
            ))}
          </TagDiv>
        </InfoDiv>
        <QuestionDiv>
          <QuestionP>{itemData.question}</QuestionP>
        </QuestionDiv>
      </QuestionSection>

      <ImageSection>
        <CSSTransition
          in={showSwiper}
          timeout={300}
          unmountOnExit
          classNames="float"
        >
          <CardSwiper
            onCardClick={(i) => {
              cardClickHandler(i);
            }}
            itemData={itemData}
            choiceIndex={choiceIndex}
          />
        </CSSTransition>
        <CSSTransition
          in={showLoading}
          timeout={300}
          unmountOnExit
          classNames="float"
        >
          <LoadingDiv>
            <BarLoader color="#000000" />
          </LoadingDiv>
        </CSSTransition>
        <CSSTransition
          in={showResult}
          timeout={300}
          classNames="float"
          unmountOnExit
        >
          <CardResult itemData={itemData} choiceIndex={choiceIndex} />
        </CSSTransition>
      </ImageSection>
      {showResult ? (
        <ResultController nextHandler={nextCardHandler}></ResultController>
      ) : (
        <Navigation tone={"white"}></Navigation>
      )}
    </Container>
  );
}

export default VotePage;
