import { combineReducers, configureStore } from "@reduxjs/toolkit";
import actionReducer from "./actionSlice";

const rootReducer = combineReducers({
  action: actionReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
