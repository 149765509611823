import React from "react";
import styled from "styled-components";
import Navigation from "../components/Navigation";
import MainHeader from "../components/MainHeader";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
`;

const ImageInsertDiv = styled.div`
  width: 100%;
  height: 45%;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);

  justify-items: center;
  align-items: center;
  padding: 0 2rem 2rem 2rem;
  box-sizing: border-box;
  gap: 1rem;
`;

const ImageBlock = styled.section`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;

  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);

  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
`;

const TitleDiv = styled.div`
  height: 5%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 0.8rem;
  font-weight: 500;
`;

const PlusButton = styled.section`
  font-size: 3rem;
  font-weight: 200;
`;

const ContentInsertDiv = styled.div`
  width: 100%;
  height: 20%;
  padding: 0 2rem 2rem 2rem;
  box-sizing: border-box;
`;

const PlaceHolder = styled.div`
  font-size: 0.8rem;
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  box-sizing: border-box;
  &:active {
    background-color: ${({ theme }) => theme.color.__main_black};
    color: white;
  }
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.03);
  border-radius: 0.7rem;
`;

function CreatePage() {
  const navigate = useNavigate();

  const onButtonClick = () => {
    ReactGA.event({
      category: "button",
      action: `click_new_vote`,
    });
    navigate("/introduction");
  };

  return (
    <Container>
      <MainHeader />
      <TitleDiv>
        <p>사진 등록하기</p>
      </TitleDiv>
      <ImageInsertDiv>
        <ImageBlock
          onClick={() => {
            onButtonClick();
          }}
        >
          <PlusButton>+</PlusButton>
        </ImageBlock>
        <ImageBlock
          onClick={() => {
            onButtonClick();
          }}
        >
          <PlusButton>+</PlusButton>
        </ImageBlock>
        <ImageBlock
          onClick={() => {
            onButtonClick();
          }}
        >
          <PlusButton>+</PlusButton>
        </ImageBlock>
        <ImageBlock
          onClick={() => {
            onButtonClick();
          }}
        >
          <PlusButton>+</PlusButton>
        </ImageBlock>
      </ImageInsertDiv>
      <TitleDiv>
        <p>고민하고 있는 선택 기준을 적어주세요</p>
      </TitleDiv>
      <ContentInsertDiv>
        <TextArea
          onClick={() => {
            onButtonClick();
          }}
        >
          <PlaceHolder>설명을 입력해주세요</PlaceHolder>
        </TextArea>
      </ContentInsertDiv>
      <Navigation tone={"white"} />
    </Container>
  );
}

export default CreatePage;
