import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import CreatePage from "./pages/CreatePage";
import StylistPage from "./pages/StylistPage";
import VotePage from "./pages/VotePage";
import IntroductionPage from "./pages/IntroductionPage";
import useTracking from "./hook/useTracking";
import DetailPage from "./pages/DetailPage";

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.__main_background_grey};
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 500px;
  max-height: 1200px;
  overflow: hidden;
`;

function App() {
  useTracking();

  return (
    <Container>
      <Routes>
        <Route path="/" element={<VotePage />} />
        <Route path="/create" element={<CreatePage />} />
        <Route path="/stylist" element={<StylistPage />} />
        <Route path="/introduction" element={<IntroductionPage />} />
        <Route path="/detail" element={<DetailPage />} />
      </Routes>
    </Container>
  );
}

export default App;
