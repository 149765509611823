import React from "react";
import styled from "styled-components";
import { BsBellFill, BsXLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import database from "../lib/firebase";
import { set, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";
import { setSentEmail } from "../slice/actionSlice";
import { useDispatch, useSelector } from "react-redux";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/img/intro_back.png);
  background-size: cover;
  background-position: center center;
`;

const Form = styled.form`
  width: 20rem;
  height: 10rem;
`;

const ContentDiv = styled.div`
  text-align: center;
  margin: 0.5rem 0;
  line-height: 1.6;
`;

const EmailInput = styled.input`
  width: 100%;
  height: 2rem;
  margin: 0.5rem 0;
  outline: none;
  border: solid 0px;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0 1rem;
  border-radius: 0.7rem;
`;

const SubmitButton = styled.section`
  width: 100%;
  height: 2rem;
  margin: 0.5rem 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 0.7rem;
  cursor: pointer;

  &:active {
    background-color: white;
    color: black;
  }
`;

const LogoDiv = styled.div`
  position: absolute;
  bottom: 3vh;
`;

const LogoImg = styled.img`
  width: 5rem;
`;

const CloseButtonSection = styled.section`
  position: relative;
  top: 1rem;
  font-size: 0.8rem;
`;

const CloseButtonP = styled.p`
  text-decoration: underline;
  cursor: pointer;
`;

function IntroductionPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const userId = uuidv4();
  const dispatch = useDispatch();
  const { sentEmail } = useSelector((state) => state.action);

  const pushToFirebase = (e) => {
    e.preventDefault();
    if (!sentEmail) {
      set(ref(database, "/user-email" + `/${userId}`), {
        email: email,
      });
    }
    ReactGA.event({
      category: "button",
      action: `submit_email`,
    });
    dispatch(setSentEmail(true));
  };

  return (
    <Container>
      <Form>
        <ContentDiv>
          폴픽은 옷 구매를 도와주는 가상의 서비스입니다. 향후 출시에 관한 과정에
          대해 듣고 싶으시다면 아래 이메일리스트를 구독해주세요
        </ContentDiv>
        <EmailInput
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        ></EmailInput>
        <SubmitButton
          onClick={(e) => {
            pushToFirebase(e);
          }}
        >
          서비스 출시 알림받기
          {/* <IconContainer>
            <BsBellFill />
          </IconContainer> */}
        </SubmitButton>
      </Form>
      <CloseButtonSection
        onClick={() => {
          navigate(-1);
        }}
      >
        <CloseButtonP>뒤로가기</CloseButtonP>
      </CloseButtonSection>
      <LogoDiv>
        <LogoImg src={"/img/logo_white.png"}></LogoImg>
      </LogoDiv>
    </Container>
  );
}

export default IntroductionPage;
