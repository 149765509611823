import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "pollpick-mvp.firebaseapp.com",
  projectId: "pollpick-mvp",
  storageBucket: "pollpick-mvp.appspot.com",
  messagingSenderId: process.env.REACT_APP_FB_MSG_SD_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MS_ID,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;
